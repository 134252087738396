import { Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { Statistics_SubscriptionStats$reflection, Statistics_AppStats$reflection, LastAssetQuoteDate$reflection, Statistics_FavoriteCount$reflection, Statistics_UsageSummary$reflection, Statistics_DateCountEntry$reflection, Statistics_UserSummary$reflection } from "./Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, union_type, record_type, array_type, string_type, option_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { cons, ofArray, empty } from "./fable_modules/fable-library.3.7.22/List.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { toArray, map, defaultArg } from "./fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { int32ToString, createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { createElement } from "react";
import * as react from "react";
import { userCountChart, paper$0027, largeText, descText } from "./Dashboard.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { useReact_useReducer_2B9E6EA0, useReact_useEffectOnce_3A5B6456, React_createDisposable_3A5B6456, useReact_useEffect_Z5234A374, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { singleton, append, delay, toList, fold } from "./fable_modules/fable-library.3.7.22/Seq.js";
import Smartphone from "@material-ui/icons/Smartphone";
import Notifications from "@material-ui/icons/Notifications";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import Group from "@material-ui/icons/Group";
import PersonAdd from "@material-ui/icons/PersonAdd";
import { map as map_1 } from "./fable_modules/fable-library.3.7.22/Array.js";
import HowToReg from "@material-ui/icons/HowToReg";
import Timer from "@material-ui/icons/Timer";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import Today from "@material-ui/icons/Today";
import Favorite from "@material-ui/icons/Favorite";

class Model extends Record {
    constructor(UserSummary, LatestUsers, TodaysUsers, UserMonth, Usage, FavoriteAssets, MissingQuotes, ErrorMsg) {
        super();
        this.UserSummary = UserSummary;
        this.LatestUsers = LatestUsers;
        this.TodaysUsers = TodaysUsers;
        this.UserMonth = UserMonth;
        this.Usage = Usage;
        this.FavoriteAssets = FavoriteAssets;
        this.MissingQuotes = MissingQuotes;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Home.Model", [], Model, () => [["UserSummary", option_type(Statistics_UserSummary$reflection())], ["LatestUsers", option_type(array_type(string_type))], ["TodaysUsers", option_type(array_type(string_type))], ["UserMonth", option_type(array_type(Statistics_DateCountEntry$reflection()))], ["Usage", option_type(Statistics_UsageSummary$reflection())], ["FavoriteAssets", option_type(array_type(Statistics_FavoriteCount$reflection()))], ["MissingQuotes", option_type(array_type(LastAssetQuoteDate$reflection()))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UserSummaryResponse", "UserMonthResponse", "LatestUsersResponse", "TodaysUsersResponse", "UsageResponse", "FavoriteResponse", "MissingQuotesResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("Home.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Statistics_UserSummary$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Statistics_UserSummary$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Statistics_DateCountEntry$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Statistics_DateCountEntry$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(string_type), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(string_type)]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(string_type), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(string_type)]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Statistics_UsageSummary$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Statistics_UsageSummary$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Statistics_FavoriteCount$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Statistics_FavoriteCount$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(LastAssetQuoteDate$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(LastAssetQuoteDate$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init() {
    return new Model(void 0, void 0, void 0, void 0, void 0, void 0, void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.UserSummary, model.LatestUsers, model.TodaysUsers, model.UserMonth, model.Usage, model.FavoriteAssets, model.MissingQuotes, "Could not fetch user count progress");
            }
            else {
                return new Model(model.UserSummary, model.LatestUsers, model.TodaysUsers, res_1.fields[0], model.Usage, model.FavoriteAssets, model.MissingQuotes, model.ErrorMsg);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.UserSummary, model.LatestUsers, model.TodaysUsers, model.UserMonth, model.Usage, model.FavoriteAssets, model.MissingQuotes, "Could not fetch latest users");
            }
            else {
                return new Model(model.UserSummary, res_2.fields[0], model.TodaysUsers, model.UserMonth, model.Usage, model.FavoriteAssets, model.MissingQuotes, model.ErrorMsg);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.UserSummary, model.LatestUsers, model.TodaysUsers, model.UserMonth, model.Usage, model.FavoriteAssets, model.MissingQuotes, "Could not fetch todays users");
            }
            else {
                return new Model(model.UserSummary, model.LatestUsers, res_3.fields[0], model.UserMonth, model.Usage, model.FavoriteAssets, model.MissingQuotes, model.ErrorMsg);
            }
        }
        case 4: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                return new Model(model.UserSummary, model.LatestUsers, model.TodaysUsers, model.UserMonth, model.Usage, model.FavoriteAssets, model.MissingQuotes, "Could not fetch usage statistics.");
            }
            else {
                return new Model(model.UserSummary, model.LatestUsers, model.TodaysUsers, model.UserMonth, res_4.fields[0], model.FavoriteAssets, model.MissingQuotes, model.ErrorMsg);
            }
        }
        case 5: {
            const res_5 = msg.fields[0];
            if (res_5.tag === 1) {
                return new Model(model.UserSummary, model.LatestUsers, model.TodaysUsers, model.UserMonth, model.Usage, model.FavoriteAssets, model.MissingQuotes, "Could not fetch favorite stats");
            }
            else {
                return new Model(model.UserSummary, model.LatestUsers, model.TodaysUsers, model.UserMonth, model.Usage, res_5.fields[0], model.MissingQuotes, model.ErrorMsg);
            }
        }
        case 6: {
            const res_6 = msg.fields[0];
            if (res_6.tag === 1) {
                return new Model(model.UserSummary, model.LatestUsers, model.TodaysUsers, model.UserMonth, model.Usage, model.FavoriteAssets, model.MissingQuotes, "Could not fetch missing asset quotes");
            }
            else {
                return new Model(model.UserSummary, model.LatestUsers, model.TodaysUsers, model.UserMonth, model.Usage, model.FavoriteAssets, res_6.fields[0], model.ErrorMsg);
            }
        }
        case 7: {
            return new Model(model.UserSummary, model.LatestUsers, model.TodaysUsers, model.UserMonth, model.Usage, model.FavoriteAssets, model.MissingQuotes, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                const err = res.fields[0];
                return new Model(model.UserSummary, model.LatestUsers, model.TodaysUsers, model.UserMonth, model.Usage, model.FavoriteAssets, model.MissingQuotes, (err.tag === 2) ? (((err.fields[0].status) === 401) ? "Unauthenticated" : "Could not fetch user statistics") : "Could not fetch user statistics");
            }
            else {
                return new Model(res.fields[0], model.LatestUsers, model.TodaysUsers, model.UserMonth, model.Usage, model.FavoriteAssets, model.MissingQuotes, model.ErrorMsg);
            }
        }
    }
}

function fetchUserStats(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(Statistics_UserSummary$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/home/user-summary", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(Statistics_UserSummary$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(Statistics_UserSummary$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchUserMonthCount(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(array_type(Statistics_DateCountEntry$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/home/user-month-count", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(Statistics_DateCountEntry$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(Statistics_DateCountEntry$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(1, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchLatestUsers(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(array_type(string_type), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/home/latest-users", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(string_type), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(string_type), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(2, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchTodaysUsers(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(array_type(string_type), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/home/todays-users", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(string_type), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(string_type), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(3, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchUsage(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(Statistics_UsageSummary$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/dashboard/usage-summary", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(Statistics_UsageSummary$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(Statistics_UsageSummary$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(4, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchMissingQuotes(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(array_type(LastAssetQuoteDate$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/asset/missing-quotes", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(LastAssetQuoteDate$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(LastAssetQuoteDate$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(6, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchMostFavorite(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(array_type(Statistics_FavoriteCount$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/asset/most-favorite", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(Statistics_FavoriteCount$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(Statistics_FavoriteCount$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(5, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function numEntry(title, content) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        flexGrow: 1,
        width: 0,
        minWidth: 130,
    }], (elems = [descText(title), largeText(content)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function entryHolder(x) {
    return createElement("div", {
        style: {
            display: "flex",
            justifyContent: "space-between",
            width: 100 + "%",
            flexWrap: "wrap",
        },
        children: Interop_reactApi.Children.toArray(Array.from(x)),
    });
}

function titleHolder(x) {
    return createElement("div", {
        style: {
            display: "flex",
            alignItems: "center",
            gap: ((0 + "px ") + 10) + "px",
        },
        children: Interop_reactApi.Children.toArray(Array.from(x)),
    });
}

const updateTimeMillis = (1000 * 60) * 2;

export function AppDashboard() {
    let elems, props_2, props_4, props_6;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const appStats = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const subStats = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(0);
    const refetchCounter = patternInput_2[0] | 0;
    const refetch = () => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let decoder, data_1, caseStrategy_2, extra_2;
            return ((decoder = Auto_generateBoxedDecoder_79988AEF(Statistics_AppStats$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
                let properties_2, headers_2;
                try {
                    const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/dashboard/app-stats", properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(Statistics_AppStats$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve(equals(Statistics_AppStats$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })()))))).then((_arg_2) => {
                const res = _arg_2;
                if (res.tag === 0) {
                    patternInput[1](res.fields[0]);
                    return Promise.resolve();
                }
                else {
                    return Promise.resolve();
                }
            });
        }));
        pr_1.then();
        const pr_3 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let decoder_3, data_5, caseStrategy_10, extra_10;
            return ((decoder_3 = Auto_generateBoxedDecoder_79988AEF(Statistics_SubscriptionStats$reflection(), void 0, void 0), (data_5 = (void 0), (caseStrategy_10 = (void 0), (extra_10 = (void 0), (() => {
                let properties_6, headers_5;
                try {
                    const properties_3_1 = Helper_withProperties(empty(), (properties_6 = ofArray([new Types_RequestProperties(0, "GET"), (headers_5 = Helper_withContentTypeJson(data_5, empty()), new Types_RequestProperties(1, keyValueList(headers_5, 0)))]), defaultArg(map((data_1_2) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_10, extra_10)(data_1_2))), properties_6), data_5), properties_6)));
                    const pr_2 = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/dashboard/subscription", properties_3_1).then((_arg_3) => {
                        let response_3, decoder_1_2;
                        return ((response_3 = _arg_3, (decoder_1_2 = defaultArg(decoder_3, Auto_generateBoxedDecoderCached_79988AEF(Statistics_SubscriptionStats$reflection(), caseStrategy_10, extra_10)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_3.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_3.text().then((_arg_4) => {
                            let matchValue_1;
                            return Promise.resolve(equals(Statistics_SubscriptionStats$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue_1 = fromString(uncurry(2, decoder_1_2), _arg_4), (matchValue_1.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue_1.fields[0]))) : (new FSharpResult$2(0, matchValue_1.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_3))))).then((_arg_1_2) => (Promise.resolve(_arg_1_2)))))))));
                    }))));
                    return pr_2.then(void 0, ((arg_7) => (new FSharpResult$2(1, new FetchError(3, arg_7)))));
                }
                catch (exn_1) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn_1))))));
                }
            })()))))).then((_arg_5) => {
                const res_1 = _arg_5;
                if (res_1.tag === 0) {
                    patternInput_1[1](res_1.fields[0]);
                    return Promise.resolve();
                }
                else {
                    return Promise.resolve();
                }
            });
        }));
        pr_3.then();
    };
    useReact_useEffect_Z5234A374(() => {
        const intervalId = window.setInterval((_arg_6) => {
            refetch();
            patternInput_2[1](refetchCounter + 1);
        }, updateTimeMillis);
        return React_createDisposable_3A5B6456(() => {
            window.clearInterval(intervalId);
        });
    }, [refetchCounter]);
    useReact_useEffectOnce_3A5B6456(() => {
        refetch();
    });
    const mapSummary = (x_4, f) => fold((_arg_8, us) => f(us), "...", toArray(x_4));
    const mapSub = (x_5, f_1) => fold((_arg_9, ss) => f_1(ss), "...", toArray(x_5));
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
        gap: ((10 + "px ") + 10) + "px",
    }], (elems = [paper$0027(500, [titleHolder([(props_2 = {}, react.createElement(Smartphone, props_2)), createElement("span", {
        children: ["App usage statistics"],
    })]), entryHolder([numEntry("# Devices", mapSummary(appStats, (us_1) => int32ToString(us_1.AppleDeviceIds + us_1.GoogleDeviceIds))), numEntry("# Apple devices", mapSummary(appStats, (us_2) => int32ToString(us_2.AppleDeviceIds))), numEntry("# Google devices", mapSummary(appStats, (us_3) => int32ToString(us_3.GoogleDeviceIds)))]), entryHolder([numEntry("# Users signed into app", mapSummary(appStats, (us_4) => int32ToString(us_4.SignedInUsers)))])]), paper$0027(500, [titleHolder([(props_4 = {}, react.createElement(Notifications, props_4)), createElement("span", {
        children: ["Alerts statistics"],
    })]), entryHolder([numEntry("# Active alerts", mapSummary(appStats, (us_5) => int32ToString(us_5.InsiderTradeAlerts + us_5.ShortAlerts))), numEntry("# Insider trade alerts", mapSummary(appStats, (us_6) => int32ToString(us_6.InsiderTradeAlerts))), numEntry("# Short alerts", mapSummary(appStats, (us_7) => int32ToString(us_7.ShortAlerts)))]), entryHolder([numEntry("# Alerts sent", mapSummary(appStats, (us_8) => int32ToString(us_8.InsiderAlertsSent + us_8.ShortAlertsSent))), numEntry("# Insider trade alerts sent", mapSummary(appStats, (us_9) => int32ToString(us_9.InsiderAlertsSent))), numEntry("# Short alerts sent", mapSummary(appStats, (us_10) => int32ToString(us_10.ShortAlertsSent)))])]), paper$0027(500, [titleHolder([(props_6 = {}, react.createElement(MonetizationOn, props_6)), createElement("span", {
        children: ["Subscriptions"],
    })]), entryHolder([numEntry("# Active subs", mapSub(subStats, (ss_1) => int32ToString(ss_1.ActiveSubs))), numEntry("#Cancelled subs", mapSub(subStats, (ss_2) => int32ToString(ss_2.CancelledSubs))), numEntry("MRR", mapSub(subStats, (ss_3) => (`${ss_3.MonthlyRecurringRev}NOK`)))]), entryHolder([numEntry("All time subs", mapSub(subStats, (ss_4) => int32ToString(ss_4.TotalSubs))), numEntry("All time revenue", mapSub(subStats, (ss_5) => (`${ss_5.AllTimeRev}NOK`)))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function Home() {
    let elems_12, elems_1, props, elems, props_7, elems_2, elems_11, elems_6, elems_10;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    const reFetchCounter = patternInput_1[0] | 0;
    const reFetch = (dispatch$0027) => {
        fetchUserStats(dispatch$0027);
        fetchLatestUsers(dispatch$0027);
        fetchTodaysUsers(dispatch$0027);
        fetchUserMonthCount(dispatch$0027);
        fetchUsage(dispatch$0027);
        fetchMissingQuotes(dispatch$0027);
        fetchMostFavorite(dispatch$0027);
    };
    useReact_useEffect_Z5234A374(() => {
        const intervalId = window.setInterval((_arg) => {
            reFetch(dispatch);
            patternInput_1[1](reFetchCounter + 1);
        }, updateTimeMillis);
        return React_createDisposable_3A5B6456(() => {
            window.clearInterval(intervalId);
        });
    }, [reFetchCounter]);
    const mapSummary = (x, f) => fold((_arg_1, us) => f(us), "...", toArray(x));
    const mapUsage = (x_1, f_1) => fold((_arg_2, us_1) => f_1(us_1), "...", toArray(x_1));
    useReact_useEffectOnce_3A5B6456(() => {
        reFetch(dispatch);
    });
    return createElement("div", createObj(ofArray([["style", {
        height: 100 + "vh",
        width: 100 + "vw",
        display: "flex",
        flexDirection: "column",
        gap: ((10 + "px ") + 10) + "px",
    }], (elems_12 = [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
        gap: ((10 + "px ") + 10) + "px",
    }], (elems_1 = [paper$0027(500, [titleHolder([(props = {}, react.createElement(Group, props)), createElement("span", {
        children: ["User count statistics"],
    })]), entryHolder([numEntry("# Users", mapSummary(model_1.UserSummary, (us_2) => int32ToString(us_2.Total))), numEntry("# Verified", mapSummary(model_1.UserSummary, (us_3) => int32ToString(us_3.Verified))), numEntry("# Enabled", mapSummary(model_1.UserSummary, (us_4) => int32ToString(us_4.Enabled)))]), entryHolder([numEntry("# Enabled \u0026 verified", mapSummary(model_1.UserSummary, (us_5) => int32ToString(us_5.EnabledVerified))), numEntry("# Google", mapSummary(model_1.UserSummary, (us_6) => int32ToString(us_6.Google))), numEntry("# Apple", mapSummary(model_1.UserSummary, (us_7) => int32ToString(us_7.Apple)))])]), paper$0027(350, [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        width: 100 + "%",
    }], (elems = toList(delay(() => {
        let props_2;
        return append(singleton(titleHolder([(props_2 = {}, react.createElement(PersonAdd, props_2)), createElement("span", {
            children: ["Newest users"],
        })])), delay(() => {
            const matchValue = model_1.LatestUsers;
            return (matchValue != null) ? singleton(map_1((y) => createElement("span", {
                style: {
                    fontSize: 1.1 + "rem",
                    fontWeight: "bold",
                    color: "#FF7F50",
                    fontFamily: "arial",
                },
                children: y,
            }), matchValue)) : singleton(createElement("span", {
                children: [".."],
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))]), paper$0027(500, [titleHolder([(props_7 = {}, react.createElement(HowToReg, props_7)), createElement("span", {
        children: ["Registered users activity"],
    })]), entryHolder([numEntry("# Today", mapUsage(model_1.Usage, (us_8) => int32ToString(us_8.Today))), numEntry("# Last 7", mapUsage(model_1.Usage, (us_9) => int32ToString(us_9.LastSeven))), numEntry("# This week", mapUsage(model_1.Usage, (us_10) => int32ToString(us_10.ThisWeek)))]), entryHolder([numEntry("# Prev week", mapUsage(model_1.Usage, (us_11) => int32ToString(us_11.PreviousWeek)))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement(AppDashboard, null), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        maxWidth: 1390,
    }], (elems_2 = [userCountChart(model_1.UserMonth)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        gap: ((10 + "px ") + 10) + "px",
        flexWrap: "wrap",
    }], (elems_11 = [paper$0027(380, toList(delay(() => {
        let props_11;
        return append(singleton(titleHolder([(props_11 = {}, react.createElement(Timer, props_11)), createElement("span", {
            children: ["Assets with oldest quotes"],
        })])), delay(() => {
            let elements_2, children_5;
            const matchValue_1 = model_1.MissingQuotes;
            return (matchValue_1 != null) ? singleton(MuiHelpers_createElement(Table, [["size", "small"], (elements_2 = [(children_5 = map_1((y_1) => {
                let matchValue_2;
                const children_4 = ofArray([MuiHelpers_createElement(TableCell, [["style", {
                    color: "#FFFFFF",
                }], ["size", "small"], ["variant", "body"], ["children", `${y_1.Ticker}.${y_1.MarketTicker}`]]), MuiHelpers_createElement(TableCell, [["style", {
                    color: "#FFFFFF",
                }], ["size", "small"], ["variant", "body"], ["children", (matchValue_2 = y_1.LastQuoteDate, (matchValue_2 != null) ? matchValue_2 : "N/A")]])]);
                return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]);
            }, matchValue_1), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])])) : singleton(MuiHelpers_createElement(CircularProgress, []));
        }));
    }))), paper$0027(380, [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        width: 100 + "%",
    }], (elems_6 = toList(delay(() => {
        let props_17;
        return append(singleton(titleHolder([(props_17 = {}, react.createElement(Today, props_17)), createElement("span", {
            children: ["Active users today"],
        })])), delay(() => {
            const matchValue_3 = model_1.TodaysUsers;
            return (matchValue_3 != null) ? singleton(map_1((y_2) => createElement("span", {
                style: {
                    fontSize: 1.1 + "rem",
                    fontWeight: "bold",
                    color: "#FF7F50",
                    fontFamily: "arial",
                },
                children: y_2,
            }), matchValue_3)) : singleton(createElement("span", {
                children: [".."],
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))]), paper$0027(380, [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        width: 100 + "%",
    }], (elems_10 = toList(delay(() => {
        let props_22;
        return append(singleton(titleHolder([(props_22 = {}, react.createElement(Favorite, props_22)), createElement("span", {
            children: ["Most favorite assets"],
        })])), delay(() => {
            let elements_5, children_9;
            const matchValue_4 = model_1.FavoriteAssets;
            return (matchValue_4 != null) ? singleton(MuiHelpers_createElement(Table, [["size", "small"], (elements_5 = [(children_9 = map_1((y_3) => {
                const children_8 = ofArray([MuiHelpers_createElement(TableCell, [["style", {
                    color: "#FFFFFF",
                }], ["size", "small"], ["variant", "body"], ["children", `${y_3.Ticker}.${y_3.Market}`]]), MuiHelpers_createElement(TableCell, [["style", {
                    color: "#FFFFFF",
                }], ["size", "small"], ["variant", "body"], ["children", int32ToString(y_3.Num)]])]);
                return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_8))]]);
            }, matchValue_4), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_9))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_5))])])) : singleton(createElement("span", {
                children: [".."],
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])));
}

