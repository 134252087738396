import { Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { unit_type, equals, obj_type, bool_type, union_type, record_type, string_type, array_type, option_type, int32_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { User as User_1, GenericResponse$reflection, Ban$reflection, User$reflection } from "./Shared/ApiDataTypes.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.22/String.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton, cons, ofArray, empty } from "./fable_modules/fable-library.3.7.22/List.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map, defaultArg } from "./fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { FunctionComponent_Of_Z5A158BBF } from "./fable_modules/Fable.React.7.4.0/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { createElement } from "react";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import DialogContent from "@material-ui/core/DialogContent";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import DialogActions from "@material-ui/core/DialogActions";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { BanForm } from "./BanForm.js";
import { snackError, pageHeader } from "./ViewHelpers.js";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { map as map_1 } from "./fable_modules/fable-library.3.7.22/Array.js";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";

class Model extends Record {
    constructor(UserId, UserInfo, Bans, ErrorMsg) {
        super();
        this.UserId = (UserId | 0);
        this.UserInfo = UserInfo;
        this.Bans = Bans;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("User.Model", [], Model, () => [["UserId", int32_type], ["UserInfo", option_type(User$reflection())], ["Bans", option_type(array_type(Ban$reflection()))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["InfoResponse", "BansResponse", "LiftBanResponse", "DeleteImageResponse", "EmailVerifyResponse", "ChangeEmailResponse", "ChangeUsernameResponse", "OfficialResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("User.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [User$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", User$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Ban$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Ban$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Ban$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Ban$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item1", bool_type], ["Item2", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init(x) {
    return new Model(x, void 0, void 0, void 0);
}

function update(model, msg) {
    let matchValue, array, matchValue_1, y_1, matchValue_2, y_2, matchValue_3, y_3, matchValue_4, y_4, matchValue_5, y_5;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.UserId, model.UserInfo, model.Bans, "Could not load user bans");
            }
            else {
                return new Model(model.UserId, model.UserInfo, res_1.fields[0], model.ErrorMsg);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                const err_2 = res_2.fields[0];
                return new Model(model.UserId, model.UserInfo, model.Bans, (err_2.tag === 2) ? (((err_2.fields[0].status) === 404) ? "Could not find ban to lift" : "Could not lift ban.") : "Could not lift ban.");
            }
            else {
                return new Model(model.UserId, model.UserInfo, (matchValue = model.Bans, (matchValue != null) ? ((array = matchValue, array.filter((b) => (b.Id !== res_2.fields[0].Id)))) : (void 0)), model.ErrorMsg);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.UserId, model.UserInfo, model.Bans, "Could not delete user image");
            }
            else {
                const x_7 = res_3.fields[0];
                if (x_7.Result === "success") {
                    return new Model(model.UserId, (matchValue_1 = model.UserInfo, (matchValue_1 != null) ? ((y_1 = matchValue_1, new User_1(y_1.Id, y_1.Username, y_1.Email, y_1.EmailVerified, y_1.Enabled, y_1.Official, y_1.Joined, void 0, y_1.Banned, y_1.RegisteredWithEmail, y_1.Google, y_1.Apple))) : (void 0)), model.Bans, model.ErrorMsg);
                }
                else {
                    return new Model(model.UserId, model.UserInfo, model.Bans, x_7.Message);
                }
            }
        }
        case 4: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                return new Model(model.UserId, model.UserInfo, model.Bans, "Could not verify user email");
            }
            else {
                const x_8 = res_4.fields[0];
                if (x_8.Result === "success") {
                    return new Model(model.UserId, (matchValue_2 = model.UserInfo, (matchValue_2 != null) ? ((y_2 = matchValue_2, new User_1(y_2.Id, y_2.Username, y_2.Email, true, y_2.Enabled, y_2.Official, y_2.Joined, y_2.ImageId, y_2.Banned, y_2.RegisteredWithEmail, y_2.Google, y_2.Apple))) : (void 0)), model.Bans, model.ErrorMsg);
                }
                else {
                    return new Model(model.UserId, model.UserInfo, model.Bans, x_8.Message);
                }
            }
        }
        case 5: {
            const res_5 = msg.fields[0];
            if (res_5.tag === 1) {
                return new Model(model.UserId, model.UserInfo, model.Bans, "Could not change user email");
            }
            else {
                const x_9 = res_5.fields[0];
                if (x_9.Result === "success") {
                    return new Model(model.UserId, (matchValue_3 = model.UserInfo, (matchValue_3 != null) ? ((y_3 = matchValue_3, new User_1(y_3.Id, y_3.Username, x_9.Message, y_3.EmailVerified, y_3.Enabled, y_3.Official, y_3.Joined, y_3.ImageId, y_3.Banned, y_3.RegisteredWithEmail, y_3.Google, y_3.Apple))) : (void 0)), model.Bans, model.ErrorMsg);
                }
                else {
                    return new Model(model.UserId, model.UserInfo, model.Bans, x_9.Message);
                }
            }
        }
        case 6: {
            const res_6 = msg.fields[0];
            if (res_6.tag === 1) {
                return new Model(model.UserId, model.UserInfo, model.Bans, "Could not change username");
            }
            else {
                const x_10 = res_6.fields[0];
                if (x_10.Result === "success") {
                    return new Model(model.UserId, (matchValue_4 = model.UserInfo, (matchValue_4 != null) ? ((y_4 = matchValue_4, new User_1(y_4.Id, x_10.Message, y_4.Email, y_4.EmailVerified, y_4.Enabled, y_4.Official, y_4.Joined, y_4.ImageId, y_4.Banned, y_4.RegisteredWithEmail, y_4.Google, y_4.Apple))) : (void 0)), model.Bans, model.ErrorMsg);
                }
                else {
                    return new Model(model.UserId, model.UserInfo, model.Bans, x_10.Message);
                }
            }
        }
        case 7: {
            const res_7 = msg.fields[1];
            if (res_7.tag === 1) {
                return new Model(model.UserId, model.UserInfo, model.Bans, "Could not update official state");
            }
            else {
                const x_11 = res_7.fields[0];
                if (x_11.Result === "success") {
                    return new Model(model.UserId, (matchValue_5 = model.UserInfo, (matchValue_5 != null) ? ((y_5 = matchValue_5, new User_1(y_5.Id, y_5.Username, y_5.Email, y_5.EmailVerified, y_5.Enabled, msg.fields[0], y_5.Joined, y_5.ImageId, y_5.Banned, y_5.RegisteredWithEmail, y_5.Google, y_5.Apple))) : (void 0)), model.Bans, model.ErrorMsg);
                }
                else {
                    return new Model(model.UserId, model.UserInfo, model.Bans, x_11.Message);
                }
            }
        }
        case 8: {
            return new Model(model.UserId, model.UserInfo, model.Bans, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                const err = res.fields[0];
                return new Model(model.UserId, model.UserInfo, model.Bans, (err.tag === 2) ? (((err.fields[0].status) === 404) ? "User not found" : "Could not load user info.") : "Could not load user info.");
            }
            else {
                return new Model(model.UserId, res.fields[0], model.Bans, model.ErrorMsg);
            }
        }
    }
}

function fetchInfo(userId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = toText(printf("/api/user/%i"))(userId);
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(User$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(User$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(User$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_4) => (new FSharpResult$2(1, new FetchError(3, arg_4)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchBans(userId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = toText(printf("/api/user/bans/%i"))(userId);
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(array_type(Ban$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(Ban$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(Ban$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_4) => (new FSharpResult$2(1, new FetchError(3, arg_4)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(1, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function liftBan(userId, banId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        const requestPath = toText(printf("/api/user/lift-ban/%i"))(userId);
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(int32_type, void 0, void 0, void 0)(banId), (decoder = Auto_generateBoxedDecoder_79988AEF(Ban$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(Ban$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(Ban$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_4) => (new FSharpResult$2(1, new FetchError(3, arg_4)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(2, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function deleteImage(imgId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(int32_type, void 0, void 0, void 0)(imgId), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/profilepicture/delete", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(3, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function verifyEmail(userId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(int32_type, void 0, void 0, void 0)(userId), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/user/verify-email", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(4, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function changeEmail(userId, email, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        const requestPath = toText(printf("/api/user/change-email/%i"))(userId);
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(string_type, void 0, void 0, void 0)(email), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_4) => (new FSharpResult$2(1, new FetchError(3, arg_4)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(5, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function changeUsername(userId, username, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        const requestPath = toText(printf("/api/user/change-username/%i"))(userId);
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(string_type, void 0, void 0, void 0)(username), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_4) => (new FSharpResult$2(1, new FetchError(3, arg_4)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(6, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function makeOfficial(userId, isOfficial, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        const requestPath = toText(printf("/api/user/official/%i"))(userId);
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(bool_type, void 0, void 0, void 0)(isOfficial), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_4) => (new FSharpResult$2(1, new FetchError(3, arg_4)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(7, isOfficial, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

const emailForm = FunctionComponent_Of_Z5A158BBF((props) => {
    let elems_3, elements_2, children, children_1;
    const patternInput = useFeliz_React__React_useState_Static_1505(props.y);
    const email = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setActive = patternInput_1[1];
    const active = patternInput_1[0];
    return createElement("div", createObj(ofArray([["style", {
        margin: 5,
    }], (elems_3 = [MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Change email"], ["onClick", (_arg) => {
        setActive(!active);
    }]]), MuiHelpers_createElement(Dialog, [["open", active], ["onClose", (_arg_2, v) => {
        setActive(false);
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Change email"]]), (children = singleton(MuiHelpers_createElement(TextField, [["style", {
        minWidth: 400,
    }], ["size", "small"], ["variant", "outlined"], ["defaultValue", email], ["label", "Email"], ["autoFocus", true], ["onChange", (e) => {
        patternInput[1](Browser_Types_Event__Event_get_Value(e));
    }], ["fullWidth", true], ["type", "email"]])), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = ofArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Close"], ["onClick", (_arg_3) => {
        setActive(false);
    }]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Update"], ["onClick", (_arg_4) => {
        changeEmail(props.x, email, props.dispatch);
        setActive(false);
    }]])]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}, void 0, uncurry(2, void 0), void 0, "emailForm", "/home/runner/work/aksjeio-admin/aksjeio-admin/src/Client/User.fs", 237);

const usernameForm = FunctionComponent_Of_Z5A158BBF((props) => {
    let elems_3, elements_2, children, children_1;
    const patternInput = useFeliz_React__React_useState_Static_1505(props.y);
    const username = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setActive = patternInput_1[1];
    const active = patternInput_1[0];
    return createElement("div", createObj(ofArray([["style", {
        margin: 5,
    }], (elems_3 = [MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Change username"], ["onClick", (_arg) => {
        setActive(!active);
    }]]), MuiHelpers_createElement(Dialog, [["open", active], ["onClose", (_arg_2, v) => {
        setActive(false);
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Change username"]]), (children = singleton(MuiHelpers_createElement(TextField, [["style", {
        minWidth: 400,
    }], ["size", "small"], ["variant", "outlined"], ["defaultValue", username], ["label", "Username"], ["autoFocus", true], ["onChange", (e) => {
        patternInput[1](Browser_Types_Event__Event_get_Value(e));
    }], ["fullWidth", true]])), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = ofArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Close"], ["onClick", (_arg_3) => {
        setActive(false);
    }]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Update"], ["onClick", (_arg_4) => {
        changeUsername(props.x, username, props.dispatch);
        setActive(false);
    }]])]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}, void 0, uncurry(2, void 0), void 0, "usernameForm", "/home/runner/work/aksjeio-admin/aksjeio-admin/src/Client/User.fs", 293);

function info(x) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        marginLeft: 10,
    }], (elems = toList(delay(() => append(singleton_1(createElement("span", {
        style: {
            fontSize: 1.5 + "rem",
            fontWeight: "bold",
        },
        children: x.Username,
    })), delay(() => {
        let children;
        return append(singleton_1((children = ofArray([createElement("span", {
            children: [x.Email],
        }), createElement("span", {
            style: {
                marginLeft: 5,
                color: x.EmailVerified ? "green" : "red",
            },
            children: x.EmailVerified ? "Verified" : "Not verified",
        })]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))), delay(() => {
            let value_18;
            return append(singleton_1((value_18 = toText(printf("Joined: %s"))(x.Joined), createElement("span", {
                children: [value_18],
            }))), delay(() => {
                let value_19;
                return append(singleton_1((value_19 = toText(printf("Enabled: %b"))(x.Enabled), createElement("span", {
                    children: [value_19],
                }))), delay(() => {
                    let value_20;
                    return append(singleton_1((value_20 = toText(printf("Official: %b"))(x.Official), createElement("span", {
                        children: [value_20],
                    }))), delay(() => {
                        let value_21;
                        return append(singleton_1((value_21 = toText(printf("Registered /w email: %b"))(x.RegisteredWithEmail), createElement("span", {
                            children: [value_21],
                        }))), delay(() => {
                            let value_22;
                            return append(singleton_1((value_22 = toText(printf("Google: %b"))(x.Google), createElement("span", {
                                children: [value_22],
                            }))), delay(() => {
                                let value_23;
                                return append(singleton_1((value_23 = toText(printf("Apple: %b"))(x.Apple), createElement("span", {
                                    children: [value_23],
                                }))), delay(() => {
                                    const matchValue = x.Banned;
                                    if (matchValue != null) {
                                        const y = matchValue;
                                        return singleton_1(createElement("span", {
                                            style: {
                                                color: "#FF0000",
                                            },
                                            children: toText(printf("Banned until %s"))(y),
                                        }));
                                    }
                                    else {
                                        return empty_1();
                                    }
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        }));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function Actions(actionsInputProps) {
    let elems_4;
    const info_1 = actionsInputProps.info;
    const dispatch = actionsInputProps.dispatch;
    const model = actionsInputProps.model;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const deleteImg = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const validEmail = patternInput_1[0];
    const setValidEmail = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setBanForm = patternInput_2[1];
    const banForm = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setOfficialForm = patternInput_3[1];
    const officialForm = patternInput_3[0];
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        marginLeft: 10,
    }], (elems_4 = toList(delay(() => {
        let matchValue, imgId, elems;
        return append((matchValue = info_1.ImageId, (matchValue != null) ? ((imgId = (matchValue | 0), singleton_1(createElement("div", createObj(ofArray([["style", {
            margin: 5,
        }], (elems = [MuiHelpers_createElement(Button, toList(delay(() => append((!deleteImg) ? singleton_1(["variant", "outlined"]) : singleton_1(["variant", "contained"]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(singleton_1(["children", "Delete image"]), delay(() => append(deleteImg ? singleton_1(["color", "secondary"]) : empty_1(), delay(() => singleton_1(["onClick", (_arg) => {
            if (!deleteImg) {
                patternInput[1](true);
            }
            else {
                deleteImage(imgId, dispatch);
            }
        }]))))))))))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))))) : ((empty_1()))), delay(() => {
            let elems_1;
            return append((!info_1.EmailVerified) ? singleton_1(createElement("div", createObj(ofArray([["style", {
                margin: 5,
            }], (elems_1 = [MuiHelpers_createElement(Button, toList(delay(() => append((!validEmail) ? singleton_1(["variant", "outlined"]) : singleton_1(["variant", "contained"]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(validEmail ? singleton_1(["color", "primary"]) : empty_1(), delay(() => append(singleton_1(["children", "Verify email"]), delay(() => singleton_1(["onClick", (_arg_1) => {
                if (!validEmail) {
                    setValidEmail(true);
                }
                else {
                    verifyEmail(info_1.Id, dispatch);
                    setValidEmail(false);
                }
            }]))))))))))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : empty_1(), delay(() => append(singleton_1(emailForm({
                dispatch: dispatch,
                x: info_1.Id,
                y: info_1.Email,
            })), delay(() => append(singleton_1(usernameForm({
                dispatch: dispatch,
                x: info_1.Id,
                y: info_1.Username,
            })), delay(() => {
                let elems_2;
                return append(singleton_1(createElement("div", createObj(ofArray([["style", {
                    margin: 5,
                }], (elems_2 = [MuiHelpers_createElement(Button, toList(delay(() => append((!officialForm) ? singleton_1(["variant", "outlined"]) : singleton_1(["variant", "contained"]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(singleton_1(["children", info_1.Official ? "Make unofficial" : "Make official"]), delay(() => append(officialForm ? singleton_1(["color", "primary"]) : empty_1(), delay(() => singleton_1(["onClick", (_arg_2) => {
                    if (!officialForm) {
                        setOfficialForm(true);
                    }
                    else {
                        makeOfficial(info_1.Id, !info_1.Official, dispatch);
                        setOfficialForm(false);
                    }
                }]))))))))))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                    let elems_3;
                    return singleton_1(createElement("div", createObj(ofArray([["style", {
                        margin: 5,
                    }], (elems_3 = toList(delay(() => append(singleton_1(MuiHelpers_createElement(Button, [["variant", "outlined"], ["size", "small"], ["children", "Ban user"], ["onClick", (_arg_3) => {
                        setBanForm(!banForm);
                    }]])), delay(() => (banForm ? singleton_1(createElement(BanForm, {
                        userId: info_1.Id,
                        onClose: (didBan) => {
                            setBanForm(false);
                        },
                    })) : empty_1()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
                }));
            }))))));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

function drawBans(model, dispatch) {
    let elems_8;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2 + "vw",
        paddingTop: 0,
    }], (elems_8 = toList(delay(() => append(singleton_1(pageHeader("Bans")), delay(() => {
        let children_6, elements_6, children_1, children, children_5;
        const matchValue = model.Bans;
        if (matchValue != null) {
            const x = matchValue;
            return (x.length === 0) ? singleton_1(createElement("span", {
                children: ["User has no active bans"],
            })) : singleton_1((children_6 = singleton(MuiHelpers_createElement(Table, [["size", "small"], (elements_6 = [(children_1 = singleton((children = ofArray([MuiHelpers_createElement(TableCell, [["children", "Until"]]), MuiHelpers_createElement(TableCell, [["children", "Since"]]), MuiHelpers_createElement(TableCell, [["children", "Reason"]]), MuiHelpers_createElement(TableCell, [])]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), (children_5 = singleton(map_1((y) => {
                let children_2, children_3;
                const children_4 = ofArray([MuiHelpers_createElement(TableCell, [["children", y.Until]]), MuiHelpers_createElement(TableCell, [["children", y.Since]]), (children_2 = singleton(createElement("p", {
                    style: {
                        fontSize: 0.7 + "rem",
                    },
                    children: y.Reason,
                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]])), (children_3 = singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Lift"], ["onClick", (_arg) => {
                    liftBan(model.UserId, y.Id, dispatch);
                }]])), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))]);
                return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]);
            }, x)), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_6))])])), MuiHelpers_createElement(TableContainer, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]])));
        }
        else {
            return singleton_1(MuiHelpers_createElement(CircularProgress, []));
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
}

function view(model, dispatch) {
    let elems;
    const children = ofArray([createElement("div", createObj(ofArray([["style", {
        display: "flex",
        alignItems: "center",
        padding: 2 + "vw",
    }], (elems = toList(delay(() => append(singleton_1(snackError(model.ErrorMsg, () => {
        dispatch(new Message(8));
    })), delay(() => {
        let matchValue_1, imgId;
        const matchValue = model.UserInfo;
        if (matchValue != null) {
            const userInfo = matchValue;
            return append((matchValue_1 = userInfo.ImageId, (matchValue_1 != null) ? ((imgId = (matchValue_1 | 0), singleton_1(MuiHelpers_createElement(Avatar, [["alt", "Profile image"], ["src", toText(printf("/api/profilepicture/%i"))(imgId)], ["style", {
                width: 300,
                height: 300,
            }]])))) : singleton_1(createElement("span", {
                children: ["No profile image"],
            }))), delay(() => append(singleton_1(info(userInfo)), delay(() => singleton_1(createElement(Actions, {
                model: model,
                dispatch: dispatch,
                info: userInfo,
            }))))));
        }
        else {
            return singleton_1(MuiHelpers_createElement(CircularProgress, []));
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), drawBans(model, dispatch)]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function User(userInputProps) {
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(userInputProps.userId));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchInfo(model_1.UserId, dispatch);
        fetchBans(model_1.UserId, dispatch);
    });
    return view(model_1, dispatch);
}

