import { Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { UserSearchEntry$reflection } from "./Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, union_type, record_type, option_type, string_type, bool_type, array_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430, Auto_generateBoxedEncoder_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { map, defaultArg, some } from "./fable_modules/fable-library.3.7.22/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty, singleton } from "./fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { int32ToString, createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { createElement } from "react";
import { pageHeader, snackError } from "./ViewHelpers.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { map as map_1 } from "./fable_modules/fable-library.3.7.22/Array.js";
import Button from "@material-ui/core/Button";
import { RouterModule_nav } from "./fable_modules/Feliz.Router.3.10.0/Router.fs.js";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { FunctionComponent_Of_Z5A158BBF } from "./fable_modules/Fable.React.7.4.0/Fable.React.FunctionComponent.fs.js";

class Model extends Record {
    constructor(Users, Processing, ErrorMsg) {
        super();
        this.Users = Users;
        this.Processing = Processing;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("UserSearch.Model", [], Model, () => [["Users", array_type(UserSearchEntry$reflection())], ["Processing", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UsersResponse", "TrySearch", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("UserSearch.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(UserSearchEntry$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(UserSearchEntry$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [], []]);
}

function init() {
    return new Model([], false, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return new Model(model.Users, true, model.ErrorMsg);
        }
        case 2: {
            return new Model(model.Users, model.Processing, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Users, false, "Could not search for users");
            }
            else {
                return new Model(res.fields[0], false, model.ErrorMsg);
            }
        }
    }
}

function trySearch(term, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new Message(1));
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(string_type, void 0, void 0, void 0)(term), (decoder = Auto_generateBoxedDecoder_79988AEF(array_type(UserSearchEntry$reflection()), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/user/search", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(UserSearchEntry$reflection()), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(UserSearchEntry$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
}

function view(model, dispatch) {
    let elems_8, elems, children_5, elements_5, children_1, children, children_4;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2 + "vw",
    }], (elems_8 = [snackError(model.ErrorMsg, () => {
        dispatch(new Message(2));
    }), pageHeader("User search"), createElement("div", createObj(ofArray([["style", {
        maxWidth: 350,
        marginBottom: 20,
    }], (elems = [MuiHelpers_createElement(TextField, [["autoFocus", true], ["type", "text"], ["defaultValue", patternInput_1[0]], ["onChange", (e) => {
        const x = Browser_Types_Event__Event_get_Value(e);
        patternInput_1[1](x);
        window.clearTimeout(patternInput[0]);
        patternInput[1](window.setTimeout((_arg_1) => {
            const pr = trySearch(x, dispatch);
            pr.then();
        }, 500));
    }], ["variant", "outlined"], ["label", "Search users"], ["style", {
        minWidth: 350 + "px",
        width: 100 + "%",
    }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), (children_5 = singleton(MuiHelpers_createElement(Table, [["size", "small"], (elements_5 = [(children_1 = singleton((children = ofArray([MuiHelpers_createElement(TableCell, [["children", "Username"]]), MuiHelpers_createElement(TableCell, [["children", "Email"]]), MuiHelpers_createElement(TableCell, [["children", "Enabled"]]), MuiHelpers_createElement(TableCell, [])]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), (children_4 = singleton(map_1((x_1) => {
        let children_2;
        const children_3 = ofArray([MuiHelpers_createElement(TableCell, [["children", x_1.Username]]), MuiHelpers_createElement(TableCell, [["children", x_1.Email]]), MuiHelpers_createElement(TableCell, [["children", x_1.Enabled ? "Enabled" : "Disabled"]]), (children_2 = singleton(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Details"], ["onClick", (_arg_2) => {
            RouterModule_nav(ofArray(["user", int32ToString(x_1.Id)]), 1, 1);
        }]])), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))]);
        return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]);
    }, model.Users)), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_5))])])), MuiHelpers_createElement(TableContainer, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
}

export const userSearch = FunctionComponent_Of_Z5A158BBF(() => {
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    return view(patternInput[0], patternInput[1]);
}, void 0, uncurry(2, void 0), void 0, "userSearch", "/home/runner/work/aksjeio-admin/aksjeio-admin/src/Client/UserSearch.fs", 132);

