import { toString } from "./fable_modules/fable-library.3.7.22/Date.js";

export function toISO8601(dt) {
    return toString(dt, "yyyy-MM-dd HH:mmZ");
}

export function trySetInputValue(fieldId, newVal) {
    try {
        const entry = window.document.getElementById(fieldId);
        entry.value = newVal;
    }
    catch (matchValue) {
    }
}

